import { isPlatform, getCode } from "./getValidProjectLocale";

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}
export const getUniqueReference = (invoiceNumber: string | number) => {
  const additionNumber = getRandomInt(9);
  const lastTimestamp = new Date().getTime().toString().substr(-5);
  const str = `${invoiceNumber}_${lastTimestamp}${additionNumber}`;
  return str.slice(0, 19);
};

export const getPayoneLanguage = (
  locale: string | undefined,
  // eslint-disable-next-line
  Payone: any
): string => {
  switch (locale) {
    case "en":
    case "en_EN":
    case "en_US":
      return Payone.ClientApi.Language.en;
    case "de":
    case "de_DE":
      return Payone.ClientApi.Language.de;
    case "fr":
    case "fr_FR":
      return Payone.ClientApi.Language.fr;
  }
  return Payone.ClientApi.Language.en;
};

export const getSuccessUrlBase = (
  brand: string,
  locale: string,
  invoiceHash?: string,
  clientHash?: string,
  txid?: string
) => {
  const base = window.location.origin;
  const code = getCode(brand);
  const platform = isPlatform(code);
  const txtidPaceholder = txid || "__txid__";
  if (platform) {
    return `${base}/${locale}/${code}/success/${txtidPaceholder}?exit=success`;
  }
  return `${base}/success/${invoiceHash}/${clientHash}/${txtidPaceholder}?exit=success`;
};

export const getErrorUrlBase = (
  brand: string,
  locale: string,
  invoiceHash?: string,
  clientHash?: string
) => {
  const base = window.location.origin;
  const code = getCode(brand);
  const platform = isPlatform(code);
  if (platform) {
    return `${base}/${locale}/${code}/error?exit=error&errorcode=__errorcode__`;
  }
  return `${base}/error/${invoiceHash}/${clientHash}?exit=error&errorcode=__errorcode__`;
};

export const getPendingUrlBase = (
  brand: string,
  locale: string,
  invoiceHash?: string,
  clientHash?: string,
  txid?: string
) => {
  const base = window.location.origin;
  const code = getCode(brand);
  const platform = isPlatform(code);
  const txtidPaceholder = txid || "__txid__";
  if (platform) {
    return `${base}/${locale}/${code}/pending/${txtidPaceholder}?exit=pending`;
  }
  return `${base}/pending/${invoiceHash}/${clientHash}/${txtidPaceholder}?exit=pending`;
};

export function getPayoneConfig(onRendered: () => void, language: string) {
  const supportedCardtypes = ["V", "M"]; // define possible cardtypes in PAYONE iFrame
  const config = {
    fields: {
      cardtype: {
        selector: "cardtype", // put name of your div-container here
        cardtypes: supportedCardtypes,
        style: "display: none",
      },
      cardpan: {
        selector: "cardpan", // put name of your div-container here
        type: "text", // text (default), password, tel
        iframe: {
          width: "100%",
          height: "50px",
        },
      },
      cardcvc2: {
        selector: "cardcvc2", // put name of your div-container here
        type: "password", // select(default), text, password, tel
        size: "4",
        maxlength: "4", // set max. length for CVC input; empty values possible
        length: { A: 4, V: 3, M: 3, J: 0 },
        // if set exact length required; 0=CVC input disabled
        iframe: {
          width: "100%",
          height: "50px",
        },
      },
      cardexpiremonth: {
        selector: "cardexpiremonth", // put name of your div-container here
        type: "select", // select(default), text, password, tel
        size: "2",
        maxlength: "2",
        iframe: {
          width: "65px",
        },
      },
      cardexpireyear: {
        selector: "cardexpireyear", // put name of your div-container here
        type: "select", // select(default), text, password, tel
        iframe: {
          width: "80px",
        },
      },
    },
    defaultStyle: {
      input: `
          padding: 10px 25px;
          height: auto;
          border-radius: 0.25rem;
          border: 1px solid rgb(211 215 220);
          background-color: #fff;
          font-size: 1.2em;
          width: 100%;
      `,
      select: `
          padding: 5px 10px;
          height: auto;
          border-radius: 0.25rem;
          border: 1px solid rgb(211 215 220);
          background-color: #fff;
          font-size: 1em;
          width: 100%;
      `,
      iframe: {
        height: "33px",
        width: "180px",
      },
    },
    autoCardtypeDetection: {
      supportedCardtypes,
    },
    events: {
      // Javascript callback function will be triggered once “hosted iFrames” are completely loaded
      rendered: onRendered,
    },
    error: "errorOutput", // area to display error-messages (optional)
    language,
  };
  return config;
}
