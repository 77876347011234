// eslint-disable react/no-danger
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Box,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
} from "@chakra-ui/react";
import { IError, InvoiceInfo } from "../types";
import getCurrencySymbol from "../utils/getCurrencySymbol";

type Props = {
  onNext: (amount: number) => void;
  data: InvoiceInfo;
  errors: IError[];
};

const centerAlign = {
  maxWidth: "120px",
  margin: "0 auto",
};

function SetAmount({ data, onNext, errors }: Props) {
  const [amount, setAmount] = useState(data?.remaining?.toString() || "");
  const { t } = useTranslation();

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setAmount((e.target as HTMLInputElement).value);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    // TODO: validate amount is positive and <= remaining value
    onNext(parseInt(amount, 10));
  };

  const currency = getCurrencySymbol("de", data?.currency?.abbreviation ?? "");
  const priceWithCurrency = ` ${data.remaining} ${currency}`;
  const isTMD = data.brand.code === "ept";
  return (
    <Box as="form" onSubmit={handleSubmit} mt={2}>
      <>
        <Text mb={1}>{t("msg#dear-customer")}</Text>
        <Text mb={4}>
          {t(
            "msg#the-remaining-amount-to-be-paid-for-this-invoice-is-default-amount",
            {
              DEFAULT_AMOUNT: data?.remaining?.toLocaleString("de"),
              CURRENCY_ABBREVIATION: currency,
              interpolation: { prefix: "%", suffix: "%" },
            }
          )}
        </Text>
        {data?.downPayment && (
          <Text mb={4}>
            {t('the-downpayment-to-be-paid-for-this-invoice-is')}{' '}
            <b>{data?.downPayment?.toLocaleString('de')} {currency}</b>{' '}
            {t('due-now')}
          </Text>
        )}
      </>
      <Box py={1}>
        <FormControl isInvalid={!!errors.length} className="text-center">
          <FormLabel htmlFor="amount" color="gray.500" fontSize="lg">
            {" "}
            {t("msg#amount")}
          </FormLabel>
          <InputGroup
            style={{ ...centerAlign, marginTop: "2px", marginBottom: "12px" }}
          >
            <InputLeftElement color="gray.300" fontSize="1.2em">
              {currency}
            </InputLeftElement>
            <Input
              placeholder={t("msg#amount")}
              type="number"
              id="amount"
              value={Math.ceil(parseInt(amount, 10))}
              onChange={onChange}
              size="lg"
              style={{ fontSize: "1.1em" }}
              autoFocus
              disabled={isTMD}
            />
          </InputGroup>
          <FormErrorMessage style={{ justifyContent: "center" }}>
            {!!errors.length && t(errors[0].msg || "")}
            {errors?.[0]?.showPrice ? priceWithCurrency : ""}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Flex justifyContent="flex-end" mt={2} style={centerAlign}>
        <Button
          isLoading={false}
          data-testid="submit_set_amount"
          type="submit"
          w="100%"
          mt={2}
        >
          {t("msg#next")}
        </Button>
      </Flex>
    </Box>
  );
}

export default memo(SetAmount);
