export const CDN_URL = 'https://cdn.venturatravel.org';

export const CURRENCIES = ['USD', 'EUR'];
// export const CURRENCIES = ['USD', 'EUR', 'GBP'];

export const DEFAULT_LOCALES: { [key: string]: string } = {
  en: 'en_US',
  de: 'en_DE',
  fr: 'fr_FR',
};
