import React from "react";
import { Text, Flex } from "@chakra-ui/react";
import NavBar from "../components/NavBar";

function NotFound() {
  return (
    <Flex
      minHeight="100vh"
      width="100%"
      flexDirection="column"
      bg="white"
      data-testid="not-found-view"
    >
      <NavBar />
      <Flex minHeight="30vh" justifyContent="center" flexWrap="wrap" mt={8}>
        <Text fontSize="4xl" textAlign="center" w="100%" as="h1" mb={6}>
          Not Found
        </Text>
      </Flex>
    </Flex>
  );
}

export default NotFound;
