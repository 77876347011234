export const API_URL = process.env.REACT_APP_API_URL;

export const NOT_READY = 'NOT_READY';
export const NORMAL = 'NORMAL';
export const SUCCESS = 'SUCCESS';
export const PENDING = 'PENDING';
export const FAIL = 'FAIL';

export const SET_INVOICE_INFO = 'SET_INVOICE_INFO';

export type Client = {
  id: number;
  first_name: string;
  last_name: string;
  computed: {
    fullname: string;
  };
  country?: {
    code?: string;
  };
  email: string;
};

export type Currency = {
  id: number;
  abbreviation: string;
};

export type Brand = {
  code: string;
  name: string;
  logo_header?: string;
  logo_footer?: string;
  base_color?: string;
};

export interface InvoiceInfo {
  id: number;
  brand: Brand;
  client: Client;
  securityCode: string;
  first_name: string;
  last_name: string;
  currency: Currency;
  current: number;
  departureDate: string;
  locale: string;
  remaining: number;
  reservationId: number;
  total: number;
  credit: number;
  trip: string;
  downPayment: number | null;
}

interface SetInvoiceInfoAction {
  type: typeof SET_INVOICE_INFO;
  payload: InvoiceInfo;
}

export type InvoiceActionTypes = SetInvoiceInfoAction;

export interface IError {
  msg?: string;
  showPrice?: boolean;
}

export interface PayOneHashResponse {
  aid: string;
  apiVersion: string;
  hash: string;
  mid: string;
  mode: string;
  portalid: string;
  encoding: string;
  request: string;
  responsetype: string;
}

export type RouteParams = {
  brand: string;
  locale: string;
  invoiceHash: string;
  clientHash: string;
  txtid: string;
};

export interface SelectOption {
  value: string;
  label: string;
}

export interface PlatformDataForm {
  invoiceNumber: string;
  amountToPay: number | string;
  currency: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  addressExtra?: string;
  city: string;
  state: string;
  country?: SelectOption | null;
  zipCode: string;
  phone: string;
}

export interface GroupDataForm {
  client_id?: number;
  client_country?: string;
  firstName?: string;
  lastName?: string;
  currency: string;
  departure?: string;
  reservationId?: number;
  trip?: string;
}
