import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend, { HttpBackendOptions } from "i18next-http-backend";
import { CDN_URL } from "./constants";

export const transformLangCode = (lang: string) => {
  if (lang.includes("_")) {
    return lang;
  }
  return `${lang}_${lang === "en" ? "US" : lang.toUpperCase()}`;
};

const customTranslations: { [key: string]: { [key: string]: string } } = {
  en_US: {
    "not-working-cta": "Back to home",
    "not-working-title": "Page under maintenance",
    "not-working-description":
      "We're currently working on this page, all hands are on deck. Please come back later In the mean time, please opt for bank transfer to make the payment.",
    "incorrect-amount": "Please enter a valid amount ",
    "invalid-last-name": "Last name does not match with the invoice",
    "applied-credit": "Applied credit",
    "some-credit-card-payments-are-declined-by-the-card-issuing-bank":
      "Some credit card payments are declined by the card-issuing bank because all credit cards have a maximum limit for individual payments. If a payment is declined, please try to spread the entire payment over several transactions.",
    "your-payment-is-beign-processed": "Your payment is being processed",
    "your-invoice-is-not-ready": "Your invoice is not ready",
    "please-note-workflow-step-1":
      "If you want to change the amount, please do not use a comma (,) to indicate the decimals, but instead, use a decimal point. For example: 3490.50 instead of e.g. 3.490,50",
    "the-downpayment-to-be-paid-for-this-invoice-is": "In order to confirm this trip and these flights, a down payment of",
    "due-now": "is due now.",
  },
  de_DE: {
    "not-working-cta": "Zurück zur Homepage",
    "not-working-title": "Seite unter Wartung",
    "not-working-description":
      "Wir arbeiten grade mit Hochdruck an der Seite. Bitte kommen Sie später wieder, oder nehmen Sie die Zahlung in der Zwischenzeit per Banküberweisung vor.",
    "incorrect-amount": "Bitte geben Sie einen gültigen Betrag ein ",
    "invalid-last-name": "Nachname stimmt nicht mit der Rechnung überein",
    "applied-credit": "Guthaben beantragt",
    "some-credit-card-payments-are-declined-by-the-card-issuing-bank":
      "Einige Kreditkartenzahlungen werden von der kartenausgebenden Bank abgelehnt, da alle Kreditkarten ein maximales Limit für einzelne Zahlungen haben. Wenn eine Zahlung abgelehnt wird, versuchen Sie bitte, die gesamte Zahlung auf mehrere Transaktionen zu verteilen",
    "your-payment-is-beign-processed": "Ihre Zahlung wird bearbeitet",
    "your-invoice-is-not-ready": "Ihre Rechnung ist noch nicht fertig",
    "please-note-workflow-step-1":
      "Wenn Sie den Betrag ändern möchten, verwenden Sie bitte kein Komma (,), um die Dezimalstellen (z. B. Cent) anzugeben. Verwenden Sie stattdessen einen Dezimalpunkt. Zum Beispiel: 3490.50 statt 3.490,50",
    "the-downpayment-to-be-paid-for-this-invoice-is": "Um diese Reise und diese Flüge zu bestätigen, ist jetzt eine Anzahlung von",
    "due-now": "fällig.",
  },
  fr_FR: {
    "not-working-cta": "Retour à la page ",
    "not-working-title": "Page en maintenance",
    "not-working-description":
      "Nous rencontrons actuellement un problème technique. Nous travaillons activement afin de rétablir la page de paiement par carte de crédit. En attendant, vous pouvez payer par virement bancaire. Veuillez nous excuser pour ce problème.",
    "incorrect-amount": "Veuillez entrer un montant valide  ",
    "invalid-last-name": "Le nom de famille ne correspond pas à la facture",
    "applied-credit": "Crédit appliqué",
    "some-credit-card-payments-are-declined-by-the-card-issuing-bank":
      "Certains paiements par carte de crédit sont refusés par la banque émettrice de la carte, car toute carte de crédit a un plafond de paiement pour chaque transaction. Si votre paiement est refusé, essayez d'étaler la totalité du paiement sur plusieurs transactions.",
    "your-payment-is-beign-processed":
      "Votre paiement est en cours de traitement",
    "your-invoice-is-not-ready": "Votre facture n'est pas prête",
    "please-note-workflow-step-1":
      "Si vous souhaitez modifier le montant, n'utilisez pas de virgule (,) pour indiquer les décimales (par exemple, les centimes). Utilisez plutôt un point décimal. Par exemple : 3490.50 au lieu de 3.490,50",
    "the-downpayment-to-be-paid-for-this-invoice-is": "Afin de confirmer ce voyage et ces vols, un acompte de",
    "due-now": "est dû dès maintenant.",
  },
  es_ES: {
    "not-working-cta": "Regresar al inicio",
    "not-working-title": "Página en mantenimiento",
    "not-working-description":
      "Actualmente estamos trabajando en esta página. Por favor, vuelva más tarde. Mientras tanto, opte por la transferencia bancaria para realizar el pago.",
    "incorrect-amount": "Por favor, introduzca una cantidad válida ",
    "invalid-last-name": "El apellido no coincide con la factura",
    "applied-credit": "Crédito aplicado",
    "some-credit-card-payments-are-declined-by-the-card-issuing-bank":
      "El banco emisor de la tarjeta rechaza algunos pagos con tarjeta de crédito, porque todas las tarjetas de crédito tienen un límite máximo para pagos individuales. Si se rechaza un pago, por favor  intente distribuir el pago completo en varias transacciones",
    "payment-details": "Datos de pago",
    "your-payment-is-beign-processed": "Su pago está siendo procesado",
    "your-invoice-is-not-ready": "Su factura no está lista",
    "please-note-workflow-step-1":
      "Si desea modificar el importe, no utilice una coma (,) para indicar los decimales (por ejemplo, céntimos). En su lugar, utilice un punto. Por ejemplo: 3490.50 en lugar de 3.490,50",
    "the-downpayment-to-be-paid-for-this-invoice-is": "Para confirmar este viaje y sus vuelos, se debe abonar ahora un anticipo de",
    "due-now": ".",
  },
  nl_NL: {
    "not-working-cta": "Terug naar home",
    "not-working-title": "Pagina in onderhoud",
    "not-working-description":
      "We werken momenteel aan deze pagina, alle hens aan dek. Komt u later nog eens terug. In de tussentijd, gelieve te kiezen voor bankoverschrijving om de betaling uit te voeren.",
    "incorrect-amount": "Voer een geldig bedrag in ",
    "invalid-last-name": "Achternaam komt niet overeen met de factuur",
    "applied-credit": "Toegepast krediet",
    "some-credit-card-payments-are-declined-by-the-card-issuing-bank":
      "Sommige creditcardbetalingen worden door de bank die de kaart uitgeeft, geweigerd omdat alle creditcards een maximumlimiet hebben voor afzonderlijke betalingen. Als een betaling wordt geweigerd, probeer dan de hele betaling over meerdere transacties te spreiden.",
    "your-payment-is-beign-processed": "Uw betaling wordt verwerkt",
    "your-invoice-is-not-ready": "Uw factuur is niet klaar",
    "please-note-workflow-step-1":
      "Als u het bedrag wilt wijzigen, gebruik dan geen komma (,) om de decimalen aan te geven, maar gebruik in plaats daarvan een decimaalteken. Bijvoorbeeld: 3490,50 in plaats van b.v. 3.490,50",
    "the-downpayment-to-be-paid-for-this-invoice-is": "Om deze reis en deze vluchten te bevestigen, is nu een aanbetaling verschuldigd van",
    "due-now": ".",
  },
};

const backendOptions: HttpBackendOptions = {
  parse: (data: any, lang: string) => {
    const parsed = JSON.parse(data);
    return {
      ...parsed,
      ...(customTranslations?.[transformLangCode(lang as string)] ?? {}),
    };
  },
  // path where resources get loaded from, or a function
  // returning a path:
  // function(lngs, namespaces) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  loadPath: (lang: any[]) =>
    `${CDN_URL}/json/prod/viventura/lang/${transformLangCode(
      lang[0],
    )}/messages.json?v=${process.env.REACT_APP_VERSION}`,
};

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: backendOptions,
    lng: "en",
    fallbackLng: "en",
    debug: ["development"].includes(process.env.NODE_ENV),
    interpolation: {
      escapeValue: false,
      prefix: "%",
      suffix: "%",
    },
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      /*
       * bindI18n: 'languageChanged',
       * bindI18nStore: '',
       * transEmptyNodeValue: '',
       * transSupportBasicHtmlNodes: true,
       * transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
       */
    },
  });

export default i18n;
