import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useSelectLocale from "../hooks/useSelectLocale";

import { FAIL, RouteParams } from "../types";
import TermsAndPrivacyLinks from "../components/TermsAndPrivacyLinks";
// import CheckoutForm from '../components/CheckoutForm';
import BrandHeader from "../components/BrandHeader";
import FeedbackMessage from "../components/FeedbackMessage";

function Success() {
  const { locale, brand } = useParams() as RouteParams;
  useSelectLocale(locale);

  return (
    <Flex flexWrap="wrap" w="100%" align="center" justify="center" bg="white">
      <Flex
        p={[3, 3, 3, 6]}
        width={["100%", "100%", "100%", "50%"]}
        h={["auto", "auto", "auto", "100vh"]}
        align="center"
        justify={["center", "cener", "center", "flex-start"]}
        boxShadow={["none", "none", "none", "md"]}
        position="relative"
        flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
      >
        <BrandHeader brand={brand} />
        <Box
          maxW="420px"
          minH="270px"
          m="0 auto"
          mb={[0, 0, 0, 6]}
          p={2}
          w="100%"
        >
          <FeedbackMessage status={FAIL} locale={locale} brand={brand} />
          <Box display={["block", "block", "block", "none"]}>
            <TermsAndPrivacyLinks />
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Success;
