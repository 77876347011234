import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useSelectLocale from "../hooks/useSelectLocale";

import { SUCCESS, RouteParams } from "../types";
import TermsAndPrivacyLinks from "../components/TermsAndPrivacyLinks";
// import CheckoutForm from '../components/CheckoutForm';
import BrandHeader from "../components/BrandHeader";
import FeedbackMessage from "../components/FeedbackMessage";

function Success() {
  const { locale, txtid, brand } = useParams() as RouteParams;
  useSelectLocale(locale);
  return (
    <Flex flexWrap="wrap" w="100%" align="center" justify="center" bg="white">
      <Flex
        p={[3, 3, 3, 6]}
        width={["100%", "100%", "100%", "50%"]}
        h={["auto", "auto", "auto", "100vh"]}
        align="center"
        justify={["center", "cener", "center", "flex-start"]}
        boxShadow={["none", "none", "none", "md"]}
        position="relative"
        flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
      >
        <BrandHeader brand={brand} />
        <Box minH="270px" mb={[0, 0, 0, 6]} p={2} w="100%">
          <FeedbackMessage
            status={SUCCESS}
            txtid={txtid}
            locale={locale}
            brand={brand}
          />
          <Box>
            <TermsAndPrivacyLinks />
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Success;
