import React from "react";
import { useTranslation } from "react-i18next";
import { BiArrowBack } from "react-icons/bi";
import { parseISO } from "date-fns";
import { Box, Text, Button, Icon, Grid, Divider } from "@chakra-ui/react";
import TermsAndPrivacyLinks from "./TermsAndPrivacyLinks";
import { InvoiceInfo } from "../types";
import getCurrencySymbol from "../utils/getCurrencySymbol";

type Props = {
  data?: InvoiceInfo;
  invoiceNumber: number | string;
  onCancel?: () => void;
};

const getDefaultLang = () => {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
};
const defaultLang = getDefaultLang();
const getDateLocale = (locale: string = defaultLang): string => {
  const US_WEIRD_FORMAT = ["en", "en-US", "en_US"];
  if (US_WEIRD_FORMAT.includes(locale)) return "en-GB";
  return locale;
};

const getDepartureDate = (data?: InvoiceInfo): string => {
  if (data?.departureDate) {
    const locale = getDateLocale(data?.locale);
    return parseISO(data.departureDate).toLocaleDateString(locale);
  }
  return "";
};

function ProductOverview({ invoiceNumber, data, onCancel }: Props) {
  const { t } = useTranslation();
  const currency = getCurrencySymbol("de", data?.currency?.abbreviation ?? "");
  const departureDate = getDepartureDate(data);

  return (
    <Box maxW="400px" p={2} mr={[0, 0, 0, "10%"]}>
      {onCancel && (
        <Button mb={4} variant="ghost" ml="-30px" onClick={onCancel}>
          <Icon as={BiArrowBack} />
          {t("msg#back")}
        </Button>
      )}
      <Text fontSize="3xl" as="h1">
        {data!.trip}
      </Text>
      {departureDate && (
        <Text fontSize="sm" color="gray.500" fontWeight="400">
          {departureDate}
        </Text>
      )}
      <Text fontSize="3xl" fontWeight="300" textAlign="end">
        {data!.total?.toLocaleString("de")} {currency}
      </Text>
      <Divider my={4} />
      <Grid templateColumns="1fr 1fr">
        <Text color="gray.600" fontWeight="600">
          {t("msg#invoice-number")}
        </Text>
        <Text color="gray.600" justifySelf="flex-end">
          {invoiceNumber}
        </Text>
        <Text color="gray.600" fontWeight="600">
          {t("msg#main-component#fullname")}
        </Text>
        <Text color="gray.600" justifySelf="flex-end">
          {`${data!.client?.first_name} ${data!.client?.last_name}`}
        </Text>
        {data?.credit ? (
          <>
            <Text color="gray.600" fontWeight="600">
              {t("applied-credit", { ns: "custom" })}
            </Text>
            <Text color="gray.600" justifySelf="flex-end">
              {data?.credit?.toLocaleString("de")} {currency}
            </Text>
          </>
        ) : null}
      </Grid>
      <Box display={["none", "none", "none", "block"]}>
        <TermsAndPrivacyLinks />
      </Box>
    </Box>
  );
}

export default ProductOverview;
