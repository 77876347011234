import React, { ComponentType } from "react";
import { useParams } from "react-router-dom";
import NotFound from "../views/NotFound";
import { isLocaleAvailable, isBrandAvailable } from "./getValidProjectLocale";
import { RouteParams } from "../types";

// resolves platform payment or group payment
const getFinalComponent = (brand: string, FallbackComponent: ComponentType) => {
  return <FallbackComponent />;
};

const withRouterValidation = (
  WrappedComponent: ComponentType
): ComponentType => {
  function RouteValidation() {
    const { brand, locale } = useParams() as RouteParams;
    return !isBrandAvailable(brand) || !isLocaleAvailable(locale) ? (
      <NotFound />
    ) : (
      getFinalComponent(brand, WrappedComponent)
    );
  }
  return RouteValidation;
};

export default withRouterValidation;
