/* eslint-disabled */
import { API_URL, PayOneHashResponse } from "../types";

interface SerialiablePlatform {
  invoiceid?: number | string; // mandatory
  reference: string;
  currency: string;
  amount: string | number;
  successurl: string;
  errorurl: string;
  param?: string; //  mandatory
}

export const getAuthorizationHash = async (
  serializableData: SerialiablePlatform
): Promise<{ hash?: string; error?: string }> => {
  try {
    const resFetch = await fetch(`${API_URL}/get-auth-hash`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(serializableData),
    });

    const resIntent = await resFetch.json();
    // setLoading(false);
    if (resFetch.status === 200) {
      return { hash: resIntent.hash };
    }
    return { error: resIntent?.errors?.[0]?.msg };
  } catch (e) {
    return { error: (e as Error).message };
  }
};

interface CreditCardCheck {
  amount: number;
  currency: string;
  isPlatform: boolean;
  invoiceNumber?: string;
  reservationId?: number;
  clientNumber?: number;
}
export const getCreditCheckHash = async (
  params: CreditCardCheck
): Promise<{
  payoneHashReponse?: PayOneHashResponse;
  error?: string;
}> => {
  try {
    const resFetch = await fetch(`${API_URL}/get-check-hash`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
    const resIntent = await resFetch.json();
    if (resFetch.status === 200) {
      return { payoneHashReponse: resIntent as PayOneHashResponse };
    }
    return { error: resIntent.errors[0].msg };
  } catch (e) {
    return { error: (e as Error).message };
  }
};
