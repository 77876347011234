import {
  extendTheme,
  withDefaultColorScheme,
  withDefaultProps,
} from '@chakra-ui/react';

// unicolor #3E515B
const customTheme = extendTheme(
  {
    colors: {
      common: {
        // https://smart-swatch.netlify.app/#3E515B
        50: '#e7f0fe',
        100: '#cdd8e3',
        200: '#b2c0cc',
        300: '#95a8b7',
        400: '#7990a1',
        500: '#3E515B',
        600: '#485e6a',
        700: '#33414d',
        800: '#1b2631',
        900: '#000a16',
      },
    },
  },
  withDefaultColorScheme({
    colorScheme: 'common',
  }),
  withDefaultProps({
    defaultProps: {
      fontSize: 'md',
    },
    components: ['Text'],
  }),
);

export default customTheme;
