import { useEffect, useCallback, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { useAppState, useAppDispatch } from "../store";
import { IError, SET_INVOICE_INFO, API_URL, InvoiceInfo } from "../types";

function useInvoiceInfo(
  invoiceNumber: Number | String,
  email: String,
  fetchOnMount: Boolean = true
) {
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IError[]>([]);
  const [data, setData] = useState(useAppState() as InvoiceInfo);
  const hasError = useMemo(() => errors.length > 0, [errors]);

  const dispatch = useAppDispatch();

  const handleQuery = useCallback(
    async (cb?: Function) => {
      try {
        setLoading(true);
        const req = await fetch(
          `${API_URL}/invoice-info?invoiceNumber=${invoiceNumber}&email=${email}`
        );
        const json = await req.json();
        if (!json.errors) {
          const { invoiceNumberId, ...payload } = json;

          setData(payload);
          dispatch({ type: SET_INVOICE_INFO, payload });

          if (cb) cb({ invoiceNumberId, ...payload });
        }
        // handle validation errors
        if (json?.errors) {
          let formattedErrors = [];
          if (Array.isArray(json.errors)) {
            formattedErrors = json.errors;
          } else {
            formattedErrors = [{ msg: json.errors }];
          }
          setErrors(formattedErrors);
        }
      } catch (e) {
        const errorMsg =
          (e as Error).message ||
          (e as IError).msg ||
          "Oops! Something went wrong";
        setErrors([errorMsg as IError]);
        toast.error(errorMsg);
      } finally {
        setLoading(false);
      }
    },
    [invoiceNumber, email, dispatch]
  );

  useEffect(() => {
    if (
      Object.keys(data).length === 0 &&
      data.constructor === Object &&
      invoiceNumber &&
      fetchOnMount
    ) {
      handleQuery();
    }
  }, [invoiceNumber, dispatch, data, setData, fetchOnMount, handleQuery]);

  return { isLoading, hasError, errors, data, query: handleQuery };
}

export default useInvoiceInfo;
