const getCurrencySymbol = (locale: string = "de", currency: string): string => {
  const n = 0;
  const parsed = n.toLocaleString(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return parsed.replace(/\d/g, "").trim();
};

export default getCurrencySymbol;
