import React, { memo } from "react";
import { Flex, Image } from "@chakra-ui/react";
import useMedia from "use-media";
import getBrandLogoUrl, { SMALLER_LOGOS } from "../utils/getBrandLogoUrl";
import getBrandColor from "../utils/getBrandColor";

type BrandHeaderProps = { brand: string; alt?: boolean };

function BrandHeader({ brand, alt }: BrandHeaderProps) {
  const isMobile = useMedia({ maxWidth: "61em" });
  const maxLogoHeight = SMALLER_LOGOS.includes(brand) ? "50px" : "80px";

  return (
    <Flex
      justifyContent="center"
      position="unset"
      background={
        alt ? "white" : ["white", "white", "white", getBrandColor(brand)]
      }
      top={0}
      left={0}
      w="100%"
      py={[2, 2, 2, 4]}
      maxH="86px"
      className="BrandHeader"
    >
      <Image
        src={getBrandLogoUrl(brand, alt ? true : isMobile)}
        alt={`${brand} logo`}
        h={["48px", "48px", "56px", maxLogoHeight]}
        alignSelf="center"
        mx={4}
        maxW="400px"
      />
    </Flex>
  );
}

export default memo(BrandHeader);
