import React from 'react';
import { Switch, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Pay from './views/Pay';
import FindInvoice from './views/FindInvoice';
import NotFound from './views/NotFound';
import Success from './views/Success';
import Pending from './views/Pending';
import ErrorView from './views/Error';
import SuccessPlatform from './views/SuccessPlatform';
import PendingPlatform from './views/PendingPlatform';
import ErrorViewPlatform from './views/ErrorPlatform';
import withRouterValidation from './utils/withRouterValidation';
import withSuspense from './utils/withSuspense';
import TagManager, { DataLayerArgs } from 'react-gtm-module';

interface TagManagerArgs extends DataLayerArgs {
  // GTM id, must be something like GTM-000000.
  gtmId: string;
  // Additional events such as 'gtm.start': new Date().getTime(),event:'gtm.js'.
  events?: object | undefined;
  // Used to set environments.
  auth?: string | undefined;
  // Used to set environments, something like env-00.
  preview?: string | undefined;
}

const tagManagerArgs: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID as string,
};

TagManager.initialize(tagManagerArgs);

export default function App() {
  // const [showMaintananceMsg, setShowMaintananceMsg] = useState(false);

  // useEffect(() => {
  //   if (showMaintananceMsg) {
  //     const showReal = window?.location?.href
  //       ?.split('?')?.[1]
  //       ?.includes('test');
  //     setShowMaintananceMsg(!showReal);
  //   }
  // }, [showMaintananceMsg]);
  // if (showMaintananceMsg) {
  //   return (
  //     <Switch>
  //       <Route
  //         path="/:locale/:brand/"
  //         component={withSuspense(withRouterValidation(WorkingOnIt))}
  //         exact
  //       />
  //       <Route path="/" component={withSuspense(WorkingOnIt)} exact />
  //       <Route path="*" component={withSuspense(NotFound)} />
  //     </Switch>
  //   );
  // }
  return (
    <Switch>
      <Route
        path="/pay/:invoiceHash/:clientHash"
        component={withSuspense(Pay)}
        exact
      />
      <Route
        path="/:locale/:brand/success/:txtid"
        component={withSuspense(withRouterValidation(SuccessPlatform))}
        exact
      />
      <Route
        path="/:locale/:brand/pending/:txtid"
        component={withSuspense(withRouterValidation(PendingPlatform))}
        exact
      />
      <Route
        path="/:locale/:brand/error"
        component={withSuspense(withRouterValidation(ErrorViewPlatform))}
        exact
      />
      <Route
        path="/success/:invoiceHash/:clientHash/:txtid"
        component={withSuspense(Success)}
        exact
      />
      <Route
        path="/pending/:invoiceHash/:clientHash/:txtid"
        component={withSuspense(Pending)}
        exact
      />
      <Route
        path="/error/:invoiceHash/:clientHash"
        component={withSuspense(ErrorView)}
        exact
      />
      <Route
        path="/:locale/:brand/"
        component={withSuspense(withRouterValidation(FindInvoice))}
        exact
      />
      <Route path="/" component={withSuspense(FindInvoice)} exact />
      <Route path="*" component={withSuspense(NotFound)} />
    </Switch>
  );
}
