import React, { useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { decode } from "../utils/Base64";
import useInvoiceInfo from "../hooks/useInvoiceInfo";
import useSelectLocale from "../hooks/useSelectLocale";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { SUCCESS, RouteParams } from "../types";
import ProductOverview from "../components/ProductOverview";
import TermsAndPrivacyLinks from "../components/TermsAndPrivacyLinks";
// import CheckoutForm from '../components/CheckoutForm';
import BrandHeader from "../components/BrandHeader";
import FeedbackMessage from "../components/FeedbackMessage";
import NotFound from "./NotFound";

function Success() {
  const { invoiceHash, clientHash } = useParams() as RouteParams;

  const invoiceNumber = decode(invoiceHash);
  const clientNumber = decode(clientHash);
  const {
    isLoading: isInitializing,
    data,
    hasError,
  } = useInvoiceInfo(invoiceNumber, clientNumber);
  useSelectLocale(data.locale);
  useDocumentTitle(data?.brand?.name ?? "Success");

  useEffect(() => {
    const redirectToEpt = async () => {
      if (!isInitializing && data?.brand?.code === "ept") {
        const redirectUrl = await fetch(
          `${process.env.REACT_APP_TMD_WEBSITE_URL}/api/trip-generator/booking-webhook?invoiceNrId=${invoiceNumber}&securityCode=${data?.securityCode}`,
          {
            method: "POST",
          }
        )
          .then((res) => res.json())
          .then((data) => data.redirectUrl);
        if (redirectUrl) window.location.href = redirectUrl;
      }
    };
    redirectToEpt();
  }, [data, isInitializing, invoiceNumber]);
  // active when webhook is ready
  // useEffect(() => {N
  // if (data && data.remaining !== undefined && data.remaining <= 0) {
  // setStatus(SUCCESS);
  // }
  // }, [data]);

  if (hasError) return <NotFound />;

  return (
    <Flex flexWrap="wrap" w="100%" align="center" justify="center" bg="white">
      <Flex
        width={["100%", "100%", "100%", "50%"]}
        justify={["center", "center", "center", "flex-end"]}
        p={[3, 3, 3, 6]}
      >
        {!isInitializing && (
          <ProductOverview data={data} invoiceNumber={invoiceNumber} />
        )}
      </Flex>
      <Flex
        width={["100%", "100%", "100%", "50%"]}
        minH={["auto", "auto", "auto", "100vh"]}
        h="100%"
        justify={["center", "center", "center", "flex-start"]}
        boxShadow={["none", "none", "none", "md"]}
        flexWrap="wrap"
        className="RightContainer"
      >
        {data.brand && <BrandHeader brand={data.brand?.name} />}
        <Box
          maxW={["385px", "400px", "420px", "420px"]}
          minH="270px"
          ml={[0, 0, 0, "10%"]}
          mb={[0, 0, 0, 6]}
          p={2}
          w="100%"
        >
          <FeedbackMessage
            status={SUCCESS}
            locale={data.locale}
            brand={data.brand?.name}
          />
          <Box display={["block", "block", "block", "none"]}>
            <TermsAndPrivacyLinks />
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Success;
