import { useEffect } from "react";
import i18n from "i18next";
import { transformLangCode } from "../i18n";

/**
 * useAutoselectLocale.
 * @param {string} Locale
 */
function useSelectLocale(locale?: string) {
  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(transformLangCode(locale));
    }
  }, [locale]);

  return locale;
}

export default useSelectLocale;
