import { useAppState } from "../store";
import { InvoiceInfo } from "../types";

// selector
function useBrand() {
  const { brand } = useAppState() as InvoiceInfo;
  return brand || "galapatours"; // FIXME use more brand validations
}

export default useBrand;
