import React, { useEffect, useState, useCallback } from "react";
import qs from "query-string";
import {
  Text,
  Box,
  Flex,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { encode } from "../utils/Base64";
import NavBar from "../components/NavBar";
import BrandHeader from "../components/BrandHeader";
import useInvoiceInfo from "../hooks/useInvoiceInfo";
import useSelectLocale from "../hooks/useSelectLocale";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { RouteParams } from "../types";
import { dataLayerPush } from "../utils/datalayerPush";

function FindInvoice() {
  const history = useHistory();
  const { t } = useTranslation();
  const { brand, locale } = useParams() as RouteParams;
  const location = useLocation();
  const { invoice_number: invoiceNumberQuery, email: emailQuery } = qs.parse(
    location.search
  );
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [email, setEmail] = useState("");

  useSelectLocale(locale);
  useDocumentTitle(brand);
  const { isLoading, hasError, errors, query } = useInvoiceInfo(
    invoiceNumber,
    email,
    false
  );

  const postQuery = useCallback(
    (data: any) => {
      console.log(data?.invoiceNumberId || invoiceNumber);
      const randomSign = btoa(`${Math.random()}||${Math.random()}`);
      history.replace(
        `/pay/${encode(data?.invoiceNumberId || invoiceNumber)}/${encode(
          email
        )}?mtm_sign=${randomSign}`
      );
    },
    [invoiceNumber, email, history]
  );

  /* Send query when invoiceNumberQuery comes as parameter */
  useEffect(() => {
    if (invoiceNumberQuery && emailQuery) {
      const invoicen = Array.isArray(invoiceNumberQuery)
        ? invoiceNumberQuery[0]
        : invoiceNumberQuery;
      const email = Array.isArray(emailQuery) ? emailQuery[0] : emailQuery;
      setInvoiceNumber(invoicen || "");
      setEmail(email || "");
      if (invoiceNumber && email) {
        query(postQuery);
      }
    }
  }, [invoiceNumberQuery, emailQuery, postQuery, query, invoiceNumber]);

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!invoiceNumber || !email) return;
    query(postQuery);
    dataLayerPush({
      event: "payment_page",
      eventCategory: "payment_clicks",
      eventAction: "continue",
      eventLabel: history.location.pathname,
    });
  };

  const onChangeInvoiceNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceNumber(e.target.value);
  };
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <Flex minHeight="100vh" width="100%" flexDirection="column" bg="white">
      <NavBar />
      <Flex minHeight="32vh" justifyContent="center" flexWrap="wrap" mt={8}>
        {brand && <BrandHeader brand={brand} alt />}
        <Box
          as="form"
          maxW="480px"
          border="1px solid #ecf2f7"
          p={4}
          mt={2}
          borderRadius="10px"
          onSubmit={onSubmit}
        >
          <Text color="gray.600">
            {t(
              "msg#viventura-allows-you-to-pay-your-deposit-and-the-remaining-amout-by-cr"
            )}
          </Text>
          <FormControl
            mt={6}
            isInvalid={hasError}
            isDisabled={isLoading}
            isRequired
          >
            <FormLabel
              htmlFor="invoice_number"
              fontWeight="600"
              color="gray.600"
            >
              {t("msg#invoice-number")}
            </FormLabel>
            <Input
              type="text"
              id="invoice_number"
              name="invoiceNumber"
              data-testid="invoice_number"
              size="lg"
              value={invoiceNumber}
              onChange={onChangeInvoiceNumber}
            />
          </FormControl>
          <FormControl
            mt={4}
            isInvalid={hasError}
            isDisabled={isLoading}
            isRequired
          >
            <FormLabel
              htmlFor="invoice_number"
              fontWeight="600"
              color="gray.600"
            >
              {t("msg#email")}
            </FormLabel>
            <Input
              id="email"
              name="email"
              data-testid="email"
              size="lg"
              value={email}
              onChange={onChangeEmail}
            />
            <FormErrorMessage my={4}>
              {hasError && t(errors[0].msg || "")}
            </FormErrorMessage>
          </FormControl>
          <Flex justifyContent="flex-end" mt={3}>
            <Button
              isLoading={isLoading}
              data-testid="submit_client_authentication"
              size="lg"
              w="100%"
              type="submit"
            >
              {t("msg#continue")}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}

export default FindInvoice;
