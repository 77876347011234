import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Flex, Box, Image, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const venUrl = "https://www.venturatravel.org/hubfs/Logo-colour.svg";

function NavBar() {
  const { t } = useTranslation();
  return (
    <Flex
      width="100%"
      bg="white"
      justifyContent="space-between"
      alignItems="center"
      height="56px"
      boxShadow="md"
    >
      <Link to="/">
        <Image src={venUrl} alt="Ventura Travel Logo" w="120px" ml={3} />
      </Link>
      <Heading fontSize="xl" data-testid="nav-head">
        {t("msg#credit-card-payment", "Credit card payment")}
      </Heading>
      <Box w="120px" />
    </Flex>
  );
}

export default memo(NavBar);
