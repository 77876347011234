import { getCode } from "./getValidProjectLocale";

type Keys = "de" | "fr" | "en" | "com" | "nl";

function getWebpageBase(brandName: string = "", locale?: string) {
  const multiBrands = [
    "galapatours",
    "indicotravels",
    "polartours",
    "fairtrips",
  ];
  const isMultiLang = multiBrands.includes(brandName);

  const brandCodeName = getCode(brandName)?.replace("-", "");
  if (!brandCodeName || !locale) return { base: "/", lang: null };
  const lang = locale.includes("_") ? locale.substr(0, 2) : locale;
  if (isMultiLang) {
    return {
      base: `https://www.${brandCodeName}.com/${lang.replace("en", "")}`,
      lang,
    };
  }
  return {
    base: `https://www.${brandCodeName}.${lang.replace("en", "com")}`,
    lang,
  };
}

function getTermsLink(brandName?: string, locale?: string) {
  if (!brandName || !locale) return "/#/";
  const { base, lang } = getWebpageBase(brandName, locale);
  const paths: Record<Keys, string> = {
    de: "agbs",
    fr: "conditions-vente",
    en: "terms",
    com: "en/terms",
    nl: "algemene-voorwaarden",
  };
  const path = paths[lang as Keys];
  return `${base}/${path}`;
}

function getPrivacyLink(brandCode?: string, locale?: string) {
  if (!brandCode || !locale) return "/#/";
  const { base, lang } = getWebpageBase(brandCode, locale);
  const paths: Record<Keys, string> = {
    de: "datenschutzerklarung",
    fr: "politique-de-confidentialite",
    en: "privacy",
    nl: "privacy",
    com: "en/privacy",
  };
  const path = paths[lang as Keys];
  return `${base}/${path}`;
}

export { getTermsLink, getPrivacyLink, getWebpageBase };
