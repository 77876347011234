import { useEffect } from "react";

function useDocumentTitle(brand?: string) {
  useEffect(() => {
    const brandCapitalize = brand
      ? brand.charAt(0).toUpperCase() + brand.slice(1)
      : "";
    const title = brand
      ? `${brandCapitalize} Payments`
      : "Ventura TRAVEL Payments";
    document.title = title;
  }, [brand]);
}

export default useDocumentTitle;
